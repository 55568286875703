<template>
<div id="app">
    <gmaps-map @mounted="ready"></gmaps-map>
    <input ref="autocomplete" id="autocomplete" class="formfield" placeholder="Search" />
</div>
</template>

<script>
import {
    gmaps,
    gmapsMap
} from 'x5-gmaps'
export default {
    name: "GeoSuggest",
    components: {
        gmaps,
        gmapsMap
    },
    data: () => ({
        autocomplete: null,
        places: [],
        map: null,
        addressData: {},
        selectedAddress: {},
        currentPlace: null
    }),
    methods: {
        ready(map) {
            this.map = map;
            gmaps().then((maps) => {
                this.places = new maps.places.PlacesService(map);
                this.autocomplete = new maps.places.Autocomplete(
                    this.$refs.autocomplete
                );
                this.autocomplete.addListener("place_changed", this.update);
            });
        },
        update() {
            const place = this.autocomplete.getPlace();
            this.currentPlace = place;

            const lat = this.currentPlace.geometry.location.lat();
            const lng = this.currentPlace.geometry.location.lng();
            const PlaceId = this.currentPlace.place_id;

            let componentForm = {
                subpremise: "short_name",
                street_number: "short_name",
                route: "long_name",
                locality: "long_name",
                administrative_area_level_1: "short_name",
                country: "short_name",
                postal_code: "short_name"
            };

            this.addressData = {};

            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                if (componentForm[addressType]) {
                    var val = place.address_components[i][componentForm[addressType]];

                    this.addressData = {
                        ...this.addressData,
                        [addressType]: val
                    };
                } else {
                    this.addressData = {
                        ...this.addressData,
                        [addressType]: ""
                    };
                }
                if (place.address_components.length === i + 1) {
                    if (this.addressData.subpremise) {
                        this.selectedAddress = {
                            ...this.selectedAddress,
                            addressLine1: this.addressData.subpremise,
                            addressLine2: this.addressData.street_number + " " + this.addressData.route,
                            suburb: this.addressData.locality,
                            state: this.addressData.administrative_area_level_1,
                            postcode: this.addressData.postal_code,
                            country: this.addressData.country,
                            latitude: lat,
                            longitude: lng,
                            addressPlaceId: PlaceId
                        };
                        this.$emit("parentToChild", this.selectedAddress);
                        return;
                    }
                    this.selectedAddress = {
                        ...this.selectedAddress,
                        addressLine1: this.addressData.street_number,
                        addressLine2: this.addressData.route,
                        suburb: this.addressData.locality,
                        state: this.addressData.administrative_area_level_1,
                        postcode: this.addressData.postal_code,
                        country: this.addressData.country,
                        latitude: lat,
                        longitude: lng,
                        addressPlaceId: PlaceId
                    };
                }
            }
            this.$emit("parentToChild", this.selectedAddress);
        }
    }
};
</script>

<style>
.formfield {
    width: 100% !important;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px
}
</style>
