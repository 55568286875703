import { axiosInstance } from "../../axios";

const GETSearchComments = `/api/Comment/SearchComments`;
const GETsearchByReferenceId = `/api/Comment/SearchCommentsByReferenceId`;
const POSTSaveRegistrationComment = `/api/Comment/CreateRegistrationComment`;
const POSTSaveCustomerComment = `/api/Comment/CreateCustomerComment`;

const SearchComments = async function(id) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(`${GETSearchComments}/${id}`);
        let data = parseList(response);
        return data;
    } catch (error) {
        return [];
    }
};

const SearchCommentsByReferenceId = async function(id) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(`${GETsearchByReferenceId}/${id}`);
        let data = parseList(response);
        return data;
    } catch (error) {
        return [];
    }
};

const SaveRegistrationComment = async function(value) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(POSTSaveRegistrationComment, value);
        return response.data;
    } catch (error) {
        return parseError(error);
    }
};

const SaveCustomerComment = async function(value) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(POSTSaveCustomerComment, value);
        return response.data;
    } catch (error) {
        return parseError(error);
    }
};

const parseList = response => {
    if (response.status !== 200) throw Error(response.message);
    if (!response.data) return [];
    let list = response.data;
    if (typeof list !== "object") {
        list = [];
    }
    return list;
};

const parseError = error => {
    if (error.response.data.Errors) {
        let stringMessage = error.response.data.Errors.join(" <br/> ");
        error.response.data.Message += "<br/>" + stringMessage;
        return error.response.data;
    } else {
        return error.response.data;
    }
};

export const commentsData = {
    SearchComments,
    SearchCommentsByReferenceId,
    SaveRegistrationComment,
    SaveCustomerComment
};